/* eslint-disable */

import React, { Component } from 'react';
import Scroll from './Scroll';
import config from '../../config';
import logo from '../assets/img/logo.jpeg';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: 'navbar-styleup',
      width: null,
    };
  }
  toggleMenu = (value) => {
    this.setState({ openMenu: value });
  };
  handleScroll = () => {
    const { visibilityClass } = this.state;
    if (window.pageYOffset > 300) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-styleup' });
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { openMenu, visibilityClass, width } = this.state;
    return (
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
        id="mainNav"
      >
        <div className="container">
          {width > 991 ? (
            <a className="navbar-brand" href="#page-top">
              <span>
                <img
                  style={{
                    width: '55px',
                    height: '55px',
                    borderRadius: 100,
                  }}
                  src={logo}
                ></img>
              </span>
            </a>
          ) : (
            <a
              className="navbar-brand"
              style={{ fontSize: '1.2em' }}
              href="#page-top"
            >
              {config.siteTitle}
            </a>
          )}
          <button
            onClick={(_) => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
            }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            {/* Menu */}
            <i className="fas fa-bars"></i>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Scroll
                  onClick={(_) => this.toggleMenu(!openMenu)}
                  type="id"
                  element="about"
                >
                  <a
                    className="nav-link"
                    style={
                      width > 991 ? { color: 'white' } : { color: '#dbb779' }
                    }
                    href="#about"
                  >
                    Quiénes somos
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={(_) => this.toggleMenu(!openMenu)}
                  type="id"
                  element="equipo"
                >
                  <a
                    className="nav-link"
                    style={
                      width > 991 ? { color: 'white' } : { color: '#dbb779' }
                    }
                    href="#equipo"
                  >
                    Nuestro equipo
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={(_) => this.toggleMenu(!openMenu)}
                  type="id"
                  element="productos"
                >
                  <a
                    className="nav-link"
                    style={
                      width > 991 ? { color: 'white' } : { color: '#dbb779' }
                    }
                    href="#productos"
                  >
                    Productos
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={(_) => this.toggleMenu(!openMenu)}
                  type="id"
                  element="contacto"
                >
                  <a
                    className="nav-link"
                    style={
                      width > 991 ? { color: 'white' } : { color: '#dbb779' }
                    }
                    href="#contacto"
                  >
                    Contactanos
                  </a>
                </Scroll>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

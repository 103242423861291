import React from 'react';
import CardUnitareBarber from './CardUnitareBarber';

function CardBarberos({ data, loading }) {
  
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {data.map((barber, index) => {
            return (
              <CardUnitareBarber
                key={index}
                barbero={barber}
              ></CardUnitareBarber>
            );
          })}
        </div>
      ) : (
        <div>
          <div
            style={{
              height: 200,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardBarberos;

import React from 'react';

import Layout from '../components/Layout';
import { Link } from 'gatsby';

const Error404 = () => (
  <Layout>
    <header
      className="masthead"
      style={{
        background:
          'url("https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1948-min_AN.jpg?alt=media&token=9ee8b877-51de-44ba-b250-dfdbaabe0819")',
        // height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div className="container" style={{}}>
        <div
          className="mx-auto text-center"
          style={{
            width: 'fit-content',
            backgroundColor: '#000000c7',
            borderRadius: '32px',
            padding: '30px',
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <h2
            className="mx-auto my-0 text-uppercase"
            style={{ color: 'white', fontSize: 52, fontWeight: 800 }}
          >
            Página no encontrada
          </h2>
          <h2
            className=" mx-auto mt-2 mb-5"
            style={{ color: '#dbb779' }}
          >
            Dirección URL no es valida
          </h2>

          <Link to="/" className="btn btn-primary">
            Volver al inicio
          </Link>
        </div>
      </div>
    </header>
  </Layout>
);

export default Error404;

/* eslint-disable */
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

import border from '../assets/images/border_1.png';

export default function Subscribe() {
  let value = new Date();

  var mes =
    value.getMonth() + 1 < 10
      ? '0' + (value.getMonth() + 1)
      : value.getMonth() + 1;
  var dia = value.getDate() < 10 ? '0' + value.getDate() : value.getDate();
  var hora = value.getHours() < 10 ? '0' + value.getHours() : value.getHours();
  var minuto =
    value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes();

  const [fecha, setFecha] = useState(
    String(
      value.getFullYear() + '-' + mes + '-' + dia + 'T' + hora + ':' + minuto
    )
  );
  const [banderaFecha, setBanderaFecha] = useState(false);
  const [correo, setCorreo] = useState('');
  const [nombre, setNombre] = useState('');
  const [banderaCorreo, setBanderaCorreo] = useState(false);

  let diasString = [
    'Domingo',
    'Lunes',
    'Martes',
    'miércoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ];

  const validar_fecha = (fecha_in) => {
    var hora = String(fecha_in).split('T');
    hora = hora[1].split(':');
    let tiempo = Number(hora[0]) * 60 + Number(hora[1]);
    console.log(new Date(String(fecha_in).replace('T', ' ')).getDay());

    if (new Date(String(fecha_in).replace('T', ' ')).getDay() !== 0) {
      if (
        tiempo > 659 &&
        tiempo < 1140 &&
        new Date(String(fecha_in).replace('T', ' ')).getDay() !== 6
      ) {
        setFecha(fecha_in);
        setBanderaFecha(false);
      } else {
        if (
          tiempo > 659 &&
          tiempo < 1110 &&
          new Date(String(fecha_in).replace('T', ' ')).getDay() === 6
        ) {
          setFecha(fecha_in);
          setBanderaFecha(false);
        } else {
          setBanderaFecha(true);
          setTimeout(
            function () {
              setBanderaFecha(false);
            }.bind(this),
            8000
          );
        }
      }
    } else {
      setBanderaFecha(true);
      setTimeout(
        function () {
          setBanderaFecha(false);
        }.bind(this),
        8000
      );
    }
  };

  const enviarMensaje = (number, fecha_in) => {
    var hora = String(fecha_in).split('T');
    var hora = hora[1].split(':');
    let tiempo = Number(hora[0]) * 60 + Number(hora[1]);
    var banderaSeguir = true;

    if (new Date(String(fecha_in).replace('T', ' ')).getDay() !== 0) {
      if (
        tiempo > 659 &&
        tiempo < 1140 &&
        new Date(String(fecha_in).replace('T', ' ')).getDay() !== 6
      ) {
        setBanderaFecha(false);
      } else {
        if (
          tiempo > 659 &&
          tiempo < 1110 &&
          new Date(String(fecha_in).replace('T', ' ')).getDay() === 6
        ) {
          setBanderaFecha(false);
        } else {
          setBanderaFecha(true);
          setTimeout(
            function () {
              setBanderaFecha(false);
            }.bind(this),
            5000
          );
          banderaSeguir = false;
        }
      }
    } else {
      setBanderaFecha(true);
      setTimeout(
        function () {
          setBanderaFecha(false);
        }.bind(this),
        5000
      );
      banderaSeguir = false;
    }

    if (
      correo === '' ||
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        correo
      )
    ) {
      banderaSeguir = false;
      setBanderaCorreo(true);
      setTimeout(
        function () {
          setBanderaCorreo(false);
        }.bind(this),
        5000
      );
    }
    if (banderaSeguir) {
      setBanderaFecha(false);
      var temp_fecha = fecha.split('T');
      let date = temp_fecha[0].split('-');
      let dia = date[2];
      let mes = date[1];
      let año = date[0];
      let message =
        'Hola! Soy ' +
        nombre +
        ' y quiero reservar una cita para el día ' +
        diasString[new Date(String(fecha_in).replace('T', ' ')).getDay()] +
        ' ' +
        dia +
        '/' +
        mes +
        '/' +
        año +
        ' a las ' +
        temp_fecha[1] +
        ' horas. Mi correo electrónico de contacto es: ' +
        String(correo).toLocaleLowerCase() +
        '.';
      var URL = 'https://wa.me';
      number = String(number)
        .replace(/[^\w\s]/gi, '')
        .replace(/ /g, '');
      var url = ''.concat(URL, '/').concat(number);
      url += '?text='.concat(encodeURI(message));
      window.open(url);
    }
  };

  return (
    <section id="contacto" className="signup-section">
      <div
        style={{
          background: `url(${border})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 0',
          width: '100%',
          height: '142px',
          zIndex: 99998,
          top: 0,
          transform: 'rotate(180deg)',
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="fa-4x mb-4 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
            </i>
            <h2
              className="text-white mb-5"
              style={{
                fontWeight: 700,
                letterSpacing: 5,
                fontSize: 42,
              }}
            >
              Reserva tu hora con nosotros
            </h2>
            {!banderaFecha ? (
              <div style={{ height: 20 }}></div>
            ) : (
              <div
                class="alert alert-warning"
                role="alert"
                style={{ height: 'fit-content', marginTop: -20 }}
              >
                <p>
                  Ingrese un horario entre las 11:00 - 19:00 Horas Lun-Vie y
                  11:00 - 18:30 Horas los Sábado.
                </p>
              </div>
            )}
            {!banderaCorreo ? (
              <div style={{ height: 20 }}></div>
            ) : (
              <div
                class="alert alert-warning"
                role="alert"
                style={{ height: 'fit-content', marginTop: -20 }}
              >
                <p>El ingreso de un Correo electrónico es obligatorio.</p>
              </div>
            )}
          </div>
          <Container style={{ justifyContent: 'center', display: 'grid' }}>
            <Row
              className="justify-content-md-center"
              style={{ textAlign: 'center' }}
              xs="1"
            >
              <Col className="form-inline" sm style={{ marginBottom: 15 }}>
                <Form.Control
                  value={fecha}
                  min={fecha}
                  type="datetime-local"
                  onChange={(e) => validar_fecha(e.target.value)}
                ></Form.Control>
              </Col>
              <Col className="form-inline_2" sm style={{ marginBottom: 15 }}>
                <Form.Control
                  style={{ width: '100%' }}
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  type="text"
                  placeholder="Nombre"
                />
              </Col>
              <Col className="form-inline_2" sm style={{ marginBottom: 15 }}>
                <Form.Control
                  style={{ width: '100%' }}
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                  type="email"
                  placeholder="Correo Electrónico"
                />
              </Col>
            </Row>

            <Row
              className="justify-content-md-center"
              style={{ textAlign: 'center' }}
              xs="1"
            >
              <Col sm="auto" style={{ marginBottom: 15 }}>
                <button
                  onClick={(e) => {
                    enviarMensaje(56936629226, fecha);
                  }}
                  style={{
                    color: 'black',
                    letterSpacing: 3,
                    fontWeight: 900,
                    fontSize: 14,
                  }}
                  className="btn btn-primary mx-auto fluid"
                >
                  Pedir hora
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

module.exports = {
  siteTitle: 'Barbershop 5ta Costa', // <title>
  manifestName: 'Barbershop 5ta Costa',
  manifestShortName: '5ta Costa', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#FFFFFF',
  manifestThemeColor: '#FFFFFF',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo.jpeg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Diego Monsalves',
  subHeading: '...',

  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/barbershop5tacosta/',
      size: '33px',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/barbershop5tacosta',
      size: '30px',
    },
    {
      icon: 'fa-whatsapp',
      name: 'WhatsApp',
      url: 'https://wa.me/56936629226',
      size: '33px',
    },
  ],
  email: 'barberia5tacosta@gmail.com',
  phone: '+569 3662 9226',
  address: 'Av Magallanes 955 Local 214, 2º piso (Tottus) Concón',
};

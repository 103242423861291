import React, { useEffect, useState } from 'react';
import { ReactPhotoCollage } from 'react-photo-collage';
import useWindowDimensions from '../useWindowDimensions';
import './gallery.css';
function Collague() {
  const { width } = useWindowDimensions();
  const [widthPage, setwidthPage] = useState(width);

  const setting = {
    width: widthPage > 768 ? '75%' : '100%',
    height: widthPage > 768 ? ['500px', '220px'] : ['250px', '100px'],
    layout: [1, 4],
    photos: [
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1945-min.jpg?alt=media&token=2d699ada-c4ff-4c15-986a-c4cdf65b7b70',
      },
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1943-min_AN.jpg?alt=media&token=697076a6-24dc-4589-bd7e-839844590550',
      },
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1950-min.jpg?alt=media&token=fc0a91e7-3bc1-49a9-a377-36cc2cb8e650',
      },
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1991-min_BN.jpg?alt=media&token=ec2638d1-3f5c-4b1c-88cf-8ddefaee0cbb',
      },
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1993-min_2.jpg?alt=media&token=7c23735c-24f5-4562-af26-b54476a91740',
      },
      // {
      //   source:
      //     'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_2065-min_AN.jpg?alt=media&token=8bbc36e2-aee4-409e-9dd9-90c9cdd5ada7',
      // },
      {
        source:
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_2096-min.jpg?alt=media&token=0a1854f0-c1f4-4033-93ae-0c21825efba6',
      },
    ],
    showNumOfRemainingPhotos: true,
  };

  useEffect(() => {
    setwidthPage(width);
  }, [width]);

  return <ReactPhotoCollage {...setting} />;
}

export default Collague;

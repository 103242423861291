/* eslint-disable */

import data from './config.json';
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getDatabase, ref, onValue } from 'firebase/database';

const config = {
  apiKey: data.apiKey,
  authDomain: data.authDomain,
  projectId: data.projectId,
  storageBucket: data.storageBucket,
  messagingSenderId: data.messagingSenderId,
  appId: data.appId,
  measurementId: data.measurementId,
};

const app = initializeApp(config);
// const analytics = getAnalytics(app);

export const getBarberos = (callback) => {
  const db = getDatabase();
  const starCountRef = ref(db, 'website/barberos');
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
    // updateStarCount(postElement, data);
  });
};

import React from 'react';
import '../style/styleVideo.css';

function HomeVideo() {
  return (
    <div className="hero-container" style={{ backgroundColor: '#161616' }}>
      <video
        src={
          'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/video%2Fpresentation1.mp4?alt=media&token=d8a0d7ef-e682-40bc-8ff0-9964a4f5b5e2'
        }
        autoPlay
        loop
        muted
        playsInline
        className="videoResize"
      />
    </div>
  );
}

export default HomeVideo;

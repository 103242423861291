/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
// import CarrouselHome from '../components/CarrouselHome';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import ProductosAsociados from '../components/ProductosAsociados';

import border from '../assets/images/border_1.png';
import HomeVideo from '../components/HomeVideo';
import EquipoTrabajo from '../components/Equipo/EquipoTrabajo';
import PagCarga from '../components/PagCarga';

import imgQuienesSomos from '../assets/images/home_barbershop.jpeg';

function IndexPage() {
  const [carga, setcarga] = useState(false);

  useEffect(() => {
    if (!carga) {
      setTimeout(() => {
        setcarga(true);
        // console.clear();
      }, 2000);
    }
  }, []);

  return (
    <Layout>
      {!carga && <PagCarga></PagCarga>}
      <div style={{ display: carga ? '' : 'none' }}>
        <Header />
        <section id="page-top">
          {/* <CarrouselHome></CarrouselHome> */}
          <HomeVideo></HomeVideo>
        </section>

        <section id="about" className="about-section text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h2 className="text-white mb-4">¿Quiénes Somos?</h2>
                <p className="text-white-50" style={{ marginBottom: 20 }}>
                  Somos una empresa dedicada a la imagen personal principalmente
                  de varones, donde buscamos mantener la tendencia de un corte
                  clásico para el cabello y en el perfilado de la Barba.
                  Envuelto hoy en día con estilos modernos y tendencias tanto
                  Americanas como Europeas.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 mx-auto" style={{ marginBottom: 25 }}>
                <img
                  style={{
                    marginBottom: 30,
                    borderStyle: 'solid',
                    borderColor: '#915c0075',
                    borderWidth: 2,
                    zoom: "40%"
                  }}
                  src={imgQuienesSomos}
                  class="img-fluid"
                  alt="..."
                />
              </div>
            </div>
          </div>
          {/**section new */}

          <div
            className="projects-section"
            style={{ backgroundColor: '#292929' }}
          >
            <div className="container">
              <div className="row align-items-center no-gutters mb-4 mb-lg-5">
                <div className="col-xl-8 col-lg-7">
                  <img
                    className="img-fluid mb-3 mb-lg-0"
                    src={
                      'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1948-min.jpg?alt=media&token=f44dd8f1-ba80-421b-8f51-6c088ec80c25'
                    }
                    alt=""
                  />
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div
                    className="featured-text text-center text-lg-left"
                    style={{ color: 'white' }}
                  >
                    <h4>Seguridad</h4>
                    <p className="text-white-50 mb-0">
                      Queremos encontrar en ti esa seguridad que tanto buscas.
                      Que en manos de nuestros profesionales, podemos conseguir
                      cada aspecto y detalle que te harán encontrar tu propia
                      identidad y confianza a ti mismo.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
                <div className="col-lg-6">
                  <img
                    className="img-fluid"
                    src={
                      'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_1946-min.jpg?alt=media&token=13a7f7c5-c4a5-4837-a11d-5163c7e67151'
                    }
                    alt=""
                  />
                </div>
                <div className="col-lg-6">
                  <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                      <div className="project-text w-100 my-auto text-center text-lg-left">
                        <h4 className="text-white">Horario</h4>
                        <p className="mb-0 text-white-50">
                          Nuestro horario es de Lunes a Viernes de 11:00 a 19:30
                          horas y los Sábado de 11:00 a 19:00 horas.
                        </p>
                        <hr className="d-none d-lg-block mb-0 ml-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center no-gutters">
                <div className="col-lg-6">
                  <img
                    className="img-fluid"
                    src={
                      'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/about%2FIMG_2079-min.jpg?alt=media&token=54febdca-1573-4e7d-8caf-225caf7d6494'
                    }
                    alt=""
                  />
                </div>
                <div className="col-lg-6 order-lg-first">
                  <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                      <div className="project-text w-100 my-auto text-center text-lg-right">
                        <h4 className="text-white">Calidad</h4>
                        <p className="mb-0 text-white-50">
                          Apuntamos ser los primeros en servicio, nos enfocamos
                          día a día en dar un mejor resultado en cada trabajo
                          que realizamos. Por lo mismo ocupamos los mejores
                          productos del mercado para llevar mejores acabados y
                          entregar el mejor servicio a nuestros clientes. Es por
                          ello que en nuestra casa encontraras marcas destacadas
                          como REUZEL, SIR FAUSTO, ENTRE OTRAS MAS.
                        </p>
                        <hr className="d-none d-lg-block mb-0 mr-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          style={{
            background: `url(${border})`,
            backgroundColor: 'rgb(41, 41, 41)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center 0',
            marginBottom: 0,
            width: '100%',
            height: '142px',
            zIndex: 99998,
          }}
        />
        <EquipoTrabajo></EquipoTrabajo>
        <div
          style={{
            background: `url(${border})`,

            backgroundColor: 'rgb(41, 41, 41)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center 0',
            width: '100%',
            height: '142px',
            zIndex: 99998,
            top: 0,
            transform: 'rotate(180deg)',
          }}
        />
        <ProductosAsociados></ProductosAsociados>
        <div
          style={{
            background: `url(${border})`,

            backgroundColor: 'rgb(41, 41, 41)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center 0',
            marginBottom: 0,
            width: '100%',
            height: '142px',
            zIndex: 99998,
          }}
        />
        {/* <EquipoTrabajo></EquipoTrabajo> */}

        <Subscribe />

        <SocialLinks />
        <Footer />
      </div>
    </Layout>
  );
}

export default IndexPage;

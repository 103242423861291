/* eslint-disable */

import React from 'react';
import logo from '../assets/img/logo.jpeg';
import '../components/Equipo/spinner.css';
function prueba() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{
          width: '120px',
          height: '120px',
          borderRadius: 100,
          marginBottom: 10,
        }}
        src={logo}
      ></img>
      <div className="spinner-container">
        <div
          className="loading-spinner"
          style={{
            border: '10px solid #915c0075',
            borderTop: '10px solid #fff',
          }}
        ></div>
      </div>
    </div>
  );
}

export default prueba;

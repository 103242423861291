import React from 'react'

import Carousel from 'react-bootstrap/Carousel'

function ProductosAsociados() {
    return (
      <section id="productos">
        <div
          className="productos-section"
          style={{ backgroundColor: '#292929' }}
        >
          <div className="container">
            <div className="row" style={{ textAlign: 'center' }}>
              <div className="col-lg-8 mx-auto" style={{ marginBottom: '0px' }}>
                <h2
                  className="text-white mb-4"
                  style={{ fontSize: 48, letterSpacing: 10, fontWeight: 600 }}
                >
                  Productos
                </h2>
              </div>
            </div>

            <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
              <div className="col-lg-6">
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/sirFausto%2Fsirfausto1.jpg?alt=media&token=197fed55-d1c3-4c1b-ae6c-cab0f1a2a3b1'
                      }
                      alt="SIR FAUSTO 1"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/sirFausto%2Fsirfausto2.jpg?alt=media&token=d781be81-26d7-4781-ac07-0fb542de5fc8'
                      }
                      alt="SIR FAUSTO 2"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/sirFausto%2Fsirfausto3.jpg?alt=media&token=51df1d5e-96a3-4118-be2e-6a1a3109e6f1'
                      }
                      alt="SIR FAUSTO 3"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/sirFausto%2Fsirfausto4.jpg?alt=media&token=158422e1-a782-4831-a9e3-b9372616cdca'
                      }
                      alt="SIR FAUSTO 4"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-lg-6">
                <div className="bg-black text-center h-100 project">
                  <div className="d-flex h-100">
                    <div className="project-text w-100 my-auto text-center text-lg-left">
                      <h4
                        className="text-white"
                        style={{ letterSpacing: 10, fontWeight: 900 }}
                      >
                        SIR FAUSTO
                      </h4>
                      <p className="mb-0 text-white-50">
                        Productos para el cuidado y fortalecimiento de la barba
                        y cabello de origen vegetal. ¿Qué esperás para vivir la
                        experiencia Sir Fausto?
                        <br></br>
                        <br></br>
                        <a
                          style={{ marginTop: 20 }}
                          href="https://sirfausto.cl"
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          https://sirfausto.cl
                        </a>
                      </p>
                      <hr className="d-none d-lg-block mb-0 ml-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center no-gutters">
              <div className="col-lg-6">
                <Carousel variant="dark">
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/reusel%2Freuzel1.jpg?alt=media&token=fdad33b2-4dac-422e-9ebf-5e5665058a96'
                      }
                      alt="REUZEL 1"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/reusel%2Freuzel2.jpg?alt=media&token=f51f9e57-70b3-4417-92f8-064c0a0af6c5'
                      }
                      alt="REUZEL 2"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/reusel%2Freuzel3.jpg?alt=media&token=5fafbc2c-2ad0-4245-9aa8-e037d6704518'
                      }
                      alt="REUZEL 3"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={
                        'https://firebasestorage.googleapis.com/v0/b/barbershop5tacosta-e7eba.appspot.com/o/reusel%2Freuzel4.jpg?alt=media&token=586304ca-b279-42da-803c-8ec79f2c8c63'
                      }
                      alt="REUZEL 4"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-lg-6 order-lg-first">
                <div className="bg-black text-center h-100 project">
                  <div className="d-flex h-100">
                    <div className="project-text w-100 my-auto text-center text-lg-left">
                      <h4
                        className="text-white"
                        style={{ letterSpacing: 10, fontWeight: 900 }}
                      >
                        REUZEL
                      </h4>
                      <p className="mb-0 text-white-50">
                        Como toda buena historia, reuzel comienza en un
                        callejón. Dos hombres enamorados de la barbería,
                        embutido con estilo y bajo las influencias del rock and
                        roll, la cultura costumbrista y la ética de la clase
                        trabajadora de Rotterdam.
                        <br></br>
                        <br></br>
                        <a
                          style={{ marginTop: 20 }}
                          href="https://www.reuzel.com"
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          https://www.reuzel.com
                        </a>{' '}
                      </p>
                      <hr className="d-none d-lg-block mb-0 ml-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default ProductosAsociados

import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../useWindowDimensions';
import { getBarberos } from '../../database/firebaseDatabase';
import CardBarberos from './CardBarberos';

import fondo from '../../assets/images/fondopixel.jpg';
import Collague from './Collague';
function EquipoTrabajo() {
  useEffect(() => {
    getBarberos((callback) => {
      setDataCard(callback);
      // setbanderaCarga(true);
      console.log(callback);
    });
  }, []);

  const [banderaCarga, setbanderaCarga] = useState(false);

  const { width } = useWindowDimensions();

  const [widthIn, setwidthIn] = useState(1000);

  useEffect(() => {
    setwidthIn(width);
  }, [width]);

  const [dataCard, setDataCard] = useState([]);

  return (
    <section id="equipo" className="text-center">
      <div
        className="equipos-section"
        style={{
          background: `url("${fondo}")`,
          backgroundRepeat: 'repeat',
          minHeight: '100% !important',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <div className="container" style={{}}>
          <div className="row">
            <div className="col-lg-8 mx-auto" style={{ marginBottom: '0px' }}>
              <h2 className="text-black mb-4">
                <b>Equipo de trabajo</b>
              </h2>
              <p className="text-black-70">
                Nuestro equipo de trabajo se compone de con
                educación en grandes academias nacionales, con el propósito de
                llevar a cabo un Excelente trabajo y el mejor servicio.
              </p>
            </div>
          </div>
          {/* <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
            <div className=" text-center h-100 equipo">
              <div
                className="d-flex h-100"
                style={widthIn > 768 ? { borderRadius: '7%' } : {}}
              >
                <CardBarberos
                  data={dataCard}
                  loading={banderaCarga}
                ></CardBarberos>
              </div>
            </div>
          </div> */}
          <div
            className="row justify-content-center no-gutters mb-5 mb-lg-0"
            style={{ marginTop: 50 }}
          >
            <Collague></Collague>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EquipoTrabajo;

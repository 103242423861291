/* eslint-disable */

import React from 'react';
import config from '../../config';

export default function SocialLinks() {
  return (
    <section className="contact-section bg-black">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i
                  className="fas fa-map-marked-alt text-primary mb-2"
                  style={{ fontSize: 30 }}
                ></i>
                <h4 className="text-uppercase m-0" style={{ fontSize: 16 }}>
                  <b>Dirección</b>
                </h4>
                <hr className="my-4" />
                <div className="small text-black-50">
                  <a
                    key={1}
                    style={{ color: 'black', fontSize: 14 }}
                    href={
                      'https://www.google.com/maps/@-32.9302656,-71.5177006,21z'
                    }
                    target="_blank"
                  >
                    <b>{config.address}</b>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i
                  className="fas fa-envelope text-primary mb-2"
                  style={{ fontSize: 30 }}
                ></i>
                <h4 className="text-uppercase m-0" style={{ fontSize: 16 }}>
                  {' '}
                  <b>Email</b>
                </h4>
                <hr className="my-4" />
                <div className="small text-black-50">
                  <a
                    style={{ color: 'black', fontSize: 14 }}
                    href={`mailto:${config.email}`}
                  >
                    <b>{config.email}</b>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i
                  className="fas fa-mobile-alt text-primary mb-2"
                  style={{ fontSize: 30 }}
                ></i>
                <h4 className="text-uppercase m-0" style={{ fontSize: 16 }}>
                  {' '}
                  <b>Número de contacto</b>
                </h4>
                <hr className="my-4" />
                <div
                  style={{ color: 'black', fontSize: 14 }}
                  className="small "
                >
                  <a style={{ color: 'black' }} href={`tel:56936629226`}>
                    <b> {config.phone}</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="social d-flex justify-content-center">
          {config.socialLinks.map((social) => {
            const { icon, url, size } = social;
            return (
              <a key={url} href={url} target="_blank" className={`mx-2`}>
                <i style={{ fontSize: size }} className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
}
